// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_rY d_gt d_cr";
export var heroHeaderCenter = "s_gv d_gv d_cr d_dv";
export var heroHeaderRight = "s_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "s_rZ d_gq d_cv";
export var heroParagraphCenter = "s_gr d_gr d_cv d_dv";
export var heroParagraphRight = "s_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "s_r0 d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "s_r1 d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "s_r2 d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "s_r3 d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "s_r4 d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "s_r5 x_r5";
export var heroExceptionNormal = "s_r6 x_r6";
export var heroExceptionLarge = "s_r7 x_r7";
export var Title1Small = "s_r8 x_r8 x_ss x_st";
export var Title1Normal = "s_r9 x_r9 x_ss x_sv";
export var Title1Large = "s_sb x_sb x_ss x_sw";
export var BodySmall = "s_sc x_sc x_ss x_sM";
export var BodyNormal = "s_sd x_sd x_ss x_sN";
export var BodyLarge = "s_sf x_sf x_ss x_sP";