// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "y_fP d_fP d_by d_bG d_bH";
export var navbarDividedRight = "y_fQ d_fQ d_by d_bH";
export var menuDesign6 = "y_tB d_fL d_by d_dv d_bN d_bH";
export var menuDesign7 = "y_tC d_fL d_by d_dv d_bN d_bH";
export var menuRight = "y_tD d_fL d_by d_dv d_bN d_bH";
export var menuLeft = "y_tF d_fL d_by d_dv d_bN d_bH";
export var menuCenter = "y_tG d_fM d_fL d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "y_p5 d_fM d_fL d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "y_qg d_fN d_fL d_by d_dv d_bN d_bH";
export var isBurger = "y_tH";
export var navbarItem = "y_p6 d_bw";
export var navbarLogoItemWrapper = "y_fX d_fX d_bB d_bN";
export var burgerToggleVisibleOpen = "y_tJ d_gc d_bx d_Y d_br";
export var burgerToggleVisible = "y_tK d_gc d_bx d_Y d_br";
export var burgerToggle = "y_tL d_gc d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "y_tM d_gc d_bx d_Y d_br";
export var burgerInput = "y_tN d_f1 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "y_tP d_f2 d_v d_G";
export var burgerLine = "y_tQ d_f0";
export var burgerMenuDesign6 = "y_tR d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "y_tS d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "y_tT d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "y_tV d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "y_tW d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "y_tX d_f8 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "y_tY d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var secondary = "y_tZ d_bB d_bN";
export var staticBurger = "y_t0";
export var menu = "y_t1";
export var navbarDividedLogo = "y_t2";
export var nav = "y_t3";